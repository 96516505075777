import { Controller } from '@hotwired/stimulus';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
  static targets = [
    'form',
    'tbody',
    'input',
    'checkedFilter',
    'sidePanel',
    'sortingInput',
    'sortingArrow',
    'headers',
    'search',
  ];

  static values = {};

  connect() {
    useRequests(this);
  }

  async submit(evt) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    const data = await this.fetch(
      `${this.formTarget.action}?${this._queryString(this.formTarget)}`
    );

    const pagination = document.querySelector('#pagination');
    pagination.outerHTML = data.pagination;

    this.tbodyTarget.innerHTML = data.list;

    if (this.headersTarget) {
      this.headersTarget.innerHTML = data.headers;
    }

    if (this.hasSearchTarget) {
      this.searchTarget.innerHTML = data.search;
    }

    const event = new CustomEvent('pagy:nav');
    this.element.dispatchEvent(event);
  }

  sort(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.sortingArrowTargets.forEach((arrow) => {
      arrow.classList.remove('text-blue-700');
    });

    const order = evt.params.order;
    const column = evt.params.column;

    this.sortingInputTarget.value = `${column}:${order}`;
    this.submit();

    evt.currentTarget.classList.add('text-blue-700');
  }

  _queryString(formTag) {
    const form = new FormData(formTag);
    return new URLSearchParams(form).toString();
  }

  displayChecked(evt) {
    if (evt.target.checked === true) {
      this.checkedFilterTarget.checked = true;
      this._disableAllInputs();
    } else {
      this.checkedFilterTarget.checked = false;
      this._enableAllInputs();
    }

    this.formTarget.querySelector('input[type="submit"]').click();
  }

  _disableAllInputs() {
    this.inputTargets.forEach((input) => {
      input.disabled = true;

      ['opacity-40', 'cursor-not-allowed'].forEach((className) => {
        input.classList.add(className);
      });
    });
  }

  _enableAllInputs() {
    this.inputTargets.forEach((input) => {
      input.disabled = false;

      ['opacity-40', 'cursor-not-allowed'].forEach((className) => {
        input.classList.remove(className);
      });
    });
  }

  async details(evt) {
    // console.log(evt);
    evt.preventDefault();
    evt.stopPropagation();

    const data = await this.fetch(evt.currentTarget.dataset.participationUrl);
    this.sidePanelTarget.innerHTML = data.html;

    this._openDetails();
  }

  async toggleLock(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const lock = evt.currentTarget.querySelector('.lock');

    const data = await this.fetch(evt.currentTarget.dataset.recordUrl, {
      method: 'PATCH',
    });

    if (data.locked === true) {
      lock.classList.add('text-red-500');
    } else {
      lock.classList.remove('text-red-500');
    }

    if (data.toast) {
      const event = new CustomEvent('toast', {
        detail: { value: data.toast },
      });

      window.dispatchEvent(event);
    }
  }

  closeDetails(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.sidePanelTarget.classList.remove('open');
  }

  _openDetails() {
    this.sidePanelTarget.classList.add('open');
  }

  checkbox;
}
