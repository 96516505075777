export const useRequests = (controller) => {
	Object.assign(controller, {
		async fetch(url, options = {}) {
			const token = document.querySelector('meta[name="csrf-token"]').content;

			let params = {
				method: options.method || 'GET',
				headers: {
					Accept: 'application/json',
					'X-CSRF-Token': token,
				},
			};

			if (options.method === 'POST' || options.method === 'PATCH') {
				params = { ...params, ...options };
			}

			const response = await fetch(url, params);
			const data = await response.json();

			return data;
		},
	});
};
