import { Controller } from '@hotwired/stimulus';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
  static targets = [
    'form'
  ];

  connect() {
    useRequests(this);
  }

  async update(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const options = {
      method: this.formTarget.method.toUpperCase(),
      body: new FormData(this.formTarget),
    };

    const url = this.formTarget.action;
    const data = await this.fetch(url, options);
    this.formTarget.outerHTML = data.html;

    if (data.toast) {
      const event = new CustomEvent('toast', {
        detail: { value: data.toast },
      });

      window.dispatchEvent(event);
    }
  }
}
