import { Controller } from '@hotwired/stimulus';
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"

export default class extends Controller {
	static values = { token: String };
  static targets = ["input"]

  connect() {
    this.geocoder = new MapboxGeocoder({
      accessToken: this.tokenValue,
    })

    this.geocoder.addTo(this.element)
    this.geocoder.query(this.inputTarget.value)
    this.geocoder.on("result", event => this._setInputValue(event))
    this.geocoder.on("clear", () => this._clearInputValue())

    setTimeout(() => {
      console.log('io')
      this.element.querySelector('.suggestions').style.display = 'none'
    }, 250)
  }

  _setInputValue(event) {
    this.inputTarget.value = event.result["place_name"]
  }
  
  _clearInputValue() {
    this.inputTarget.value = ""
  }

  disconnect() {
    this.geocoder.onRemove()
  }
}
